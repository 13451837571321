<div class="wizardPage">
  <div class="headSection col-md-12 clearfix">
  <a href="https://www.utiliko.io/" target="_blank"> <img class="siteLogo" src="../../../assets/images/logo/wizardLogo.png">
  </a>
  <span class="wizard-logout pull-right">
    <button mat-raised-button color="primary" class="btn-submit" (click)="logout()">
      <!-- <mat-icon>exit_to_app</mat-icon> -->
      Sign Out
    </button></span>
  </div>
  <div class="wizardSection col-md-10 row">
    <!-- /.login-logo -->
    <div class="threeStepForm col-md-8">
      <mat-horizontal-stepper linear #stepper>
        <mat-step [stepControl]="firstFormGroup">
          <ng-template matStepLabel>Tell us</ng-template>
          <form [formGroup]="firstFormGroup" (ngSubmit)="form1()" #formone="ngForm">

            <div class="form-div">
                <ng-select [items]="industryData" bindLabel="label"
                bindValue="value" formControlName="industry" placeholder="Industry *" id="industry" (change)="updateIndustry()" class="custom-select"  [ngClass]="submit===true ? 'errorClass' :'noError'"  required>
                </ng-select>
              <span class="formHint">We'll use this to create accounts common for your industry.</span>
            </div>

            <div class="form-div">
                <ng-select [items]="companyData" bindLabel="label"
                bindValue="value"(change)="updateCompany()"
                [ngClass]="checkCompany===true ? 'errorClass' :'noError'" name="companyType" formControlName="companyType" placeholder="Company Type *" class="companyType-css custom-select" id="companyType" required>
                </ng-select>
              <span class="formHint">We'll use this to select the right tax settings for your business</span>
            </div>

              <mat-form-field appearance="outline">
                <mat-label>Tax ID</mat-label>
                <input matInput placeholder="Tax ID" formControlName="fein" >
              </mat-form-field >
              <span class="formHint">We will use this on your tax forms</span>
            <div class="btn-class">
              <button mat-raised-button class="btn-submit" color="primary" matStepperNext>Continue</button>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="secondFormGroup">
          <ng-template matStepLabel>Contact Info</ng-template>
          <form [formGroup]="secondFormGroup" (ngSubmit)="form2(stepper)" #formtwo="ngForm">
            <mat-form-field appearance="outline">
              <mat-label>Legal Name</mat-label>
              <input matInput placeholder="Legal Name" formControlName="legalName" required>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Address</mat-label>
              <input matInput placeholder="Address" formControlName="address" name="address" ngx-google-places-autocomplete  #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)" required>
            </mat-form-field>
            <div class="row">
              <div class="col-md-6 input-box-padding">
                <mat-form-field appearance="outline">
                  <mat-label>City, State, Zip</mat-label>
                  <input matInput placeholder="City, State, Zip" formControlName="cityStateZip" (blur)="checkbillingcityStateZip()" required>
                </mat-form-field>
                <div *ngIf="showError" class="alert-msg">{{errorMsg}}</div>
                <div *ngIf="showError1" class="alert-msg">{{errorMsg1}}</div>
              </div>
              <div class="col-md-6 input-box-padding">
                <mat-form-field appearance="outline">
                  <mat-label>Country</mat-label>
                  <input matInput placeholder="Country" formControlName="country" required>
                </mat-form-field>
              </div>
            </div>
            <mat-form-field appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput placeholder="Email" formControlName="email" required>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Website</mat-label>
              <input matInput placeholder="Website" formControlName="website" >
            </mat-form-field>
            <div class="btn-class mt-10">
              <button mat-raised-button class="pull-left btn-cancel form-button-40 mat-raised-button" color="primary" matStepperPrevious>Back</button>
              <button mat-raised-button class="btn-submit" color="primary" type="submit">Continue</button>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="thirdFormGroup">
          <ng-template matStepLabel *ngIf="!paymentInfoFlag">All Set!</ng-template>
          <ng-template matStepLabel *ngIf="paymentInfoFlag">App Info</ng-template>
          <form [formGroup]="thirdFormGroup" (ngSubmit)="form3(stepper)" #formthree="ngForm">
            <div class="col-sm-12 centerAlign"> Select Apps that you want to subscribe <br>(Default Apps are already checked) </div>
            <div class="col-sm-6 col-xs-6 mobile-alignment" *ngFor="let mainMenu of moduleList; trackBy: trackByIndex">
              <div class="checkbox-fade fade-in-primary" *ngIf="mainMenu.label !== 'subscription'">
                <mat-checkbox name="selectModules" value="{{mainMenu.menuId}}" formControlName="selectModules" type="checkbox" [indeterminate]="mainMenu.ischecked" [disabled]="mainMenu.isDisabledCheck" (change)="onCheckboxChange(mainMenu,$event)" > {{mainMenu.label | titlecase}}
                </mat-checkbox>
                <span class="module-cost"> {{mainMenu.costText}} </span>
              </div>
            </div>
            <div class="col-sm-12 centerAlign">
              <mat-checkbox name="addPaymentInfo" formControlName="addPaymentInfo" type="checkbox" (change)="addPaymentInfo($event)" > Do you want to add payment info?
              </mat-checkbox>
            </div>
            <div class="btn-class col-xs-12">
              <button mat-raised-button class="pull-left btn-cancel" color="primary" matStepperPrevious>Back</button>
              <button mat-raised-button class="btn-submit" type="button" color="primary" (click)="formone.ngSubmit.emit();formtwo.ngSubmit.emit();formthree.ngSubmit.emit();" *ngIf="!paymentInfoFlag"> Submit </button>
              <button mat-raised-button color="primary" class="btn-submit" matStepperNext  *ngIf="paymentInfoFlag">Continue</button>
              
              <!-- <button mat-stroked-button color="warn" (click)="stepper.reset()">Reset</button> -->
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="fourthFormGroup" *ngIf="paymentInfoFlag">
          <ng-template matStepLabel>Payment Info</ng-template>
          <div class="form-div">
            <div class="showfileds">
              <html class="html-tsys">
              <head>
              </head>
              <body style="background:#ffffff;">
                <form method="POST" id="payment-form" class="clearfix">
                  <div class="col-md-12 pl-0">
                    <div class="label-field">Credit Card Number</div>
                    <div id="tsep-cardNumDiv" class="tsys-div"></div>
                  </div>
                  <div class="col-md-4 pl-0">
                    <div class="label-field">Zip Code</div>
                    <div id="tsep-zipcode" class="form-group mb tsys-div">
                      <input #textInput type="text" class="form-control" id="zipcode" name="zipcode" placeholder="00000"
                        (input)="textInput.value.length > 4  && SearchData(textInput.value)" required>
                    </div>
                  </div>
                  <div class="col-md-4 pl-0">
                    <div class="label-field">Exp Date</div>
                    <div id="tsep-datepickerDiv" class="tsys-div"></div>
                  </div>
                  <div class="col-md-4 pl-0">
                    <div class="label-field">CVV</div>
                    <div id="tsep-cvv2Div" class="tsys-div"></div>
                  </div>
                  <div id="div_console"></div>
                </form>
              </body>

              </html>
            </div>
            
            <div class="card-checkboxes col-md-12 mt-10" >
              <div class="text-info">
                <i class="fa fa-info-circle"></i>
                <span>&nbsp;&nbsp; Hit the tab button or click outside the input box after filling out the CVV field.</span>
              </div>

              <!-- <mat-checkbox name="isDefaultCard" (change)="defaultCcCheck($event)" id="checkbox1"
                [disabled]="true" [checked]="defaultCC">
                <span class="field_label">Make this credit card as default</span>
              </mat-checkbox> -->
            </div>
            <!-- <hr class="col-md-12"> -->
            <div class="col-md-12 mb-10">
              <mat-radio-group [(ngModel)]="radioValue">
                <mat-radio-button (change)="radioChange($event)" required [value]=1 [checked]="radioValue == 1? true : false">Start 14 days free trial</mat-radio-button> &nbsp;&nbsp;
                <mat-radio-button (change)="radioChange($event)" [value]=2 [checked]="radioValue == 2 ? true : false" required>I want to start subscription</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="col-md-12" *ngIf="subscriptionSelected">
              <mat-radio-group [(ngModel)]="subscriptionValue">
                <mat-radio-button (change)="selectSubscriptionType(1)" required [value]=1 [checked]="subscriptionValue == 1 ? true : false">Monthly</mat-radio-button> &nbsp;&nbsp;
                <mat-radio-button (change)="selectSubscriptionType(2)" [value]=2 [checked]="subscriptionValue == 2 ? true : false" required>Yearly</mat-radio-button>
              </mat-radio-group>
              <div class="text-info mb-10 mt-10" *ngIf="subscriptionValue == 1">
                <span class="text-danger">*</span> You are getting charged for <b>{{remainingDaysToEndMonth}} days</b> of this month & your monthly payment cycle will start at 1st of next month. You are getting charged for <b>{{displaytotal| number : '1.2-2' | commonDate: 'money_format' | commonDate :'currency_format'}}</b>.
              </div>
              <div class="text-info mb-10 mt-10" *ngIf="subscriptionValue == 2">
                <span class="text-danger">*</span> You are getting charged for <b>a year</b>. You are getting charged for <b>{{displaytotal| number : '1.2-2' | commonDate: 'money_format' | commonDate :'currency_format'}}</b>.
              </div>
            </div>
          </div>
          <div class="btn-class mb-10">
            <button class="pull-left btn-cancel form-button-40 mat-raised-button" color="primary" matStepperPrevious>Back</button>
            <button mat-raised-button class="btn-submit"  type="button" color="primary" (click)="formone.ngSubmit.emit();formtwo.ngSubmit.emit();formthree.ngSubmit.emit(); submitWizard();" [disabled]="currentStatus == '2'" *ngIf="!subscriptionSelected"> Submit </button>
            <button mat-raised-button class="btn-submit" type="button" color="primary" (click)="formone.ngSubmit.emit();formtwo.ngSubmit.emit();formthree.ngSubmit.emit(); submitWizardWithCC();" [disabled]="currentStatus == '2'" *ngIf="subscriptionSelected"> Pay & Activate </button>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
    <div class="col-md-4 right_layer">
      <img src="../../../assets/images/background/rightlayer.png">
    </div>
  </div>
</div>
<app-spinner></app-spinner>
